



























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseHeader extends Vue {
  active = 0

  onClick (idx: number): void {
    this.active = idx
  }
}
