/*
 * @FilePath: /lark-activity/src/utils/validate.ts
 * @Description:
 */

import regular from './regular'

/**
 *  验证是否是手机号码
 */
export const validatePhone = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入正确的手机号码'))
  } else if (!regular.phone.test(value)) {
    callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}

/**
 * 验证是否是正确的密码格式
 */
export const validatePassword = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (!regular.password.test(value)) {
    callback(new Error('密码格式不正确'))
  } else {
    callback()
  }
}

/**
 * 验证码
 */
export const codeCheck = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('请输入验证码'))
  } else {
    callback()
  }
}

// export const validateRequired = (text: string) => (rule: any, value: any, callback: any) => {
//   if (typeof (value) !== 'number') {
//     if (!value) {
//       callback(new Error(getMessage(text)))
//     } else {
//       callback()
//     }
//   } else {
//     const content = value.toString()
//     if (!content.length) {
//       callback(new Error(getMessage(text)))
//     } else {
//       callback()
//     }
//   }
// }
