/*
 * @FilePath: /lark-activity/src/lib/ViewUI.ts
 * @Description:
 */
import Vue from 'vue'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'

Vue.use(ViewUI)
