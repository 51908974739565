























































import { Component, Vue } from 'vue-property-decorator'
import VerificationCode from '@/components/VerificationCode.vue'
import { sendRegMessage, register } from '@/api/passport'
import { getQueryString } from '@/utils/index'
import { getLocalTimeZone } from '@/utils/timeZone'
import { RegisterParams } from '@/type'
import {

  validatePhone,
  codeCheck,
  // validateRequired,
  validatePassword
} from '@/utils/validate'

import ReferrerRelayUtil from '@/utils/referrerRelayUtil'

@Component({
  components: {
    VerificationCode
  }
})
export default class RegisterBox extends Vue {
  private local = 'zh-CN'

  isSee = false

  formCustom= {
    phone: '',
    code: '',
    passwd: '',
    passwdCheck: '',
    inviteCode: '',
    sourceCode: '',
    refererUri: ''
  }

  ruleCustom= {
    phone: [
      {
        validator: validatePhone,
        trigger: 'blur'
      }
    ],
    code: [
      {
        validator: codeCheck,
        trigger: 'blur'
      }
    ],
    passwd: [
      { validator: validatePassword, trigger: 'blur' }
    ]
  }

  sendRegMessage (phone: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this.$refs.formCustom as any).validateField('phone', (error: string) => {
        if (!error) {
          sendRegMessage(phone)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          reject(error)
        }
      })
    })
  }

  /** 获取验证码 */
  async getSmsCode (): Promise<void> {
    const codeBtn = this.$refs.codeBtn as any

    try {
      const res: any = await this.sendRegMessage(this.formCustom.phone)
      const code: number = res.data.code

      if (code !== 0) {
        return
      }
      this.$Message.success('已发送验证码')
      codeBtn.start()
    } catch (error) {
      this.$Message.error('请输入正确的手机号码')
    }
  }

  /**
   * 获取用户邀请码
   */
  private getInviteCode () {
    const code: string | undefined = getQueryString('code') || window.localStorage.inviteCode

    if (code) {
      window.localStorage.inviteCode = code
      this.formCustom.inviteCode = code
    }
  }

  /**
   * 获取用户来源
   */
  private getReferer () {
    const referer: string = new ReferrerRelayUtil().getItem()
    if (referer) {
      this.formCustom.refererUri = referer
    }
  }

  handleSubmit (name: string): void {
    (this.$refs[name] as any).validate((valid: boolean) => {
      if (valid) {
        const data: RegisterParams = {
          password: this.formCustom.passwd,
          language: this.local || 'zh-CN',
          source: this.formCustom.sourceCode,
          timeZone: JSON.stringify(getLocalTimeZone()),
          code: this.formCustom.code,
          phone: this.formCustom.phone,
          refererUri: this.formCustom.refererUri
        }

        const { inviteCode } = this.formCustom
        if (inviteCode) {
          data.inviteCode = inviteCode
        }

        this.handleRegister(data)
      } else {
        this.$Message.error('提交注册失败！')
      }
    })
  }

  async handleRegister (data: RegisterParams): Promise<void> {
    console.log(data)
    const res = await register(data)
    const resData = res.data

    const code: number = resData.code
    const msg: string = resData.msg
    if (code !== 0) {
      this.$Message.error(msg)
      return
    }

    this.$Message.success('注册成功，请下载安装包使用！')
  }

  mounted (): void{
    this.getInviteCode()
    this.getReferer()
  }
}
