

















import { Component, Vue } from 'vue-property-decorator'
import YAML from 'yamljs'

@Component
export default class Download extends Vue {
  private yamlUrl = 'https://yunlark.oss-cn-beijing.aliyuncs.com/download/latest.yml'

  downloadUrl = ''

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted () {
    const data = YAML.load(this.yamlUrl)
    this.downloadUrl = `https://yunlark.oss-cn-beijing.aliyuncs.com/download/${data.path}`
  }

  clickDownload (): void {
    window.location.href = this.downloadUrl
  }
}
