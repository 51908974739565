






























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Service extends Vue {
  items = [
    {
      id: 0,
      title: '账号防关联',
      content: '电子商务平台中建立多个账户店铺，提高营收，以及降低运营风险',
      icon: 'icon-zhanghaoguanli'
    },
    {
      id: 1,
      title: '广告联盟',
      content: '利用多个广告账号投放，批量操作，提高投放流水，提高ROI',
      icon: 'icon-guanggao'
    },
    {
      id: 2,
      title: '广告验证',
      content: '通过不同属性的设置，比如IP、设备等信息，模拟目标，验证广告',
      icon: 'icon-anquan'
    },
    {
      id: 3,
      title: '社交媒体营销',
      content: '同时在多个媒体账户里操作团队协作，提高效率',
      icon: 'icon-13'
    },
    {
      id: 4,
      title: '零售价格对比',
      content: '零售商通过一个真实访客来比较价格避免账号封锁或被骗',
      icon: 'icon-icon-test'
    },
    {
      id: 5,
      title: '网络爬虫',
      content: '提供下现成指纹工具的调用接口快速开发',
      icon: 'icon-wangye'
    }
  ]
}
