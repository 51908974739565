import '@/utils/setRemUtil'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/lib/ViewUI'
import '@/styles/reset.css'
import '@/styles/viewui-reset.css'
import '@/styles/yunlark.less'
import '@/styles/base.css'
import '@/styles/iconfont/iconfont.css'
import '@/styles/anim.css'
import './anim'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
