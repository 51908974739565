

















import { Component, Vue } from 'vue-property-decorator'
import DownloadBox from './DownloadBox.vue'
import RegisterBox from './RegisterBox.vue'
@Component({
  components: {
    DownloadBox,
    RegisterBox
  }

})
export default class MainBody extends Vue {
}
