/*
 * @FilePath: /lark-activity/src/utils/referrerRelayUtil.ts
 * @Description:
 */

class ReferrerRelayUtil {
  private _name = 'REFERRER_URI'

  private _domain = '.yunlark.com'

  saveItem (): void {
    const referrer = this.getReferer()
    if (referrer !== '') {
      const data = { REFERRER_URI: referrer }
      const jsonData = JSON.stringify(data)
      this.setCookie(this._name, referrer)
      window.name = jsonData
    }
  }

  getItem (): string {
    return this.getReferer()
  }

  private getRefererByWinName (): string {
    try {
      const jsonData = window.name
      const data = JSON.parse(jsonData)

      if (this.trueTypeOf(data) !== 'object') {
        return ''
      }

      const REFERRER_URI = data[this._name]
      return REFERRER_URI || ''
    } catch (error) {
      return ''
    }
  }

  private getRefererByDocument (): string {
    const referrer = document.referrer
    try {
      const urlObj = new URL(referrer)
      const index = urlObj.origin.indexOf(this._domain)
      return index === -1 ? referrer : ''
    } catch (error) {
      return ''
    }
  }

  private getRefererByCookie (): string {
    return this.getCookie(this._name)
  }

  private getRefererByQueryString (): string {
    const value = this.getQueryString(this._name.toLowerCase())
    return value || ''
  }

  private getReferer (): string {
    const referrer = this.getRefererByQueryString() || this.getRefererByWinName() || this.getRefererByCookie() || this.getRefererByDocument()
    return referrer
  }

  private trueTypeOf (v: any): string {
    const type = Object.prototype.toString.call(v).slice(8, -1).toLowerCase()
    return type === 'number' && isNaN(v) ? 'nan' : type
  }

  private setCookie = (cname: string, cvalue: string):void => {
    // const d = new Date()
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    // const expires = 'expires=' + d.toUTCString()
    const domain = 'domain=' + this._domain
    document.cookie = cname + '=' + cvalue + '; ' + domain
  }

  private getCookie = (cname: string): string => {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim()
      if (c.indexOf(name) === 0) { return c.substring(name.length, c.length) }
    }
    return ''
  }

  // 获取 url 的参数
  private getQueryString = (name: string, url?: string): string| null => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
    let newUrl = ''
    if (url) {
      const index = url.indexOf('?')
      newUrl = url.substr(index)
    } else {
      newUrl = window.location.search
    }
    const r = newUrl.substr(1).match(reg)
    if (r != null) { return unescape(this.getCharFromUtf8(r[2])) } return null
  }

  // 将编码转换成字符
  private utf8ToChar = (str: string): string => {
    const iCode = parseInt(`0x${str.substr(1, 2)}`, 16)
    const iCode1 = parseInt(`0x${str.substr(4, 2)}`, 16)
    const iCode2 = parseInt(`0x${str.substr(7, 2)}`, 16)
    // tslint:disable-next-line: no-bitwise
    return String.fromCharCode(((iCode & 0x0F) << 12) | ((iCode1 & 0x3F) << 6) | (iCode2 & 0x3F))
  }

  private getCharFromUtf8 = (str: string): string => {
    let cstr = ''
    let nOffset = 0
    if (str === '') {
      return ''
    }
    str = str.toLowerCase()
    nOffset = str.indexOf('%e')
    if (nOffset === -1) {
      return str
    }
    while (nOffset !== -1) {
      cstr += str.substr(0, nOffset)
      str = str.substr(nOffset, str.length - nOffset)
      if (str === '' || str.length < 9) {
        return cstr
      }
      cstr += this.utf8ToChar(str.substr(0, 9))
      str = str.substr(9, str.length - 9)
      nOffset = str.indexOf('%e')
    }
    return cstr + str
  }
}

export default ReferrerRelayUtil
