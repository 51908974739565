/*
 * @FilePath: /lark-activity/src/utils/timeZone.ts
 * @Description:
 */

import moment from 'moment'

// 默认时区 零时区
export const getDefaultTimeZone = (): any => ({ tz: 'GMT+00:00', gmt: 'Europe/London' })

export const getTimeZone = (): any[] => [
  { tz: 'GMT-09:00', gmt: 'America/Metlakatla' },
  { tz: 'GMT-12:00', gmt: 'Etc/GMT+12' },
  { tz: 'GMT-11:00', gmt: 'Etc/GMT+11' },
  { tz: 'GMT-11:00', gmt: 'Pacific/Midway' },
  { tz: 'GMT-11:00', gmt: 'Pacific/Niue' },
  { tz: 'GMT-11:00', gmt: 'Pacific/Pago Pago' },
  { tz: 'GMT-10:00', gmt: 'America/Adak' },
  { tz: 'GMT-10:00', gmt: 'Etc/GMT+10' },
  { tz: 'GMT-10:00', gmt: 'HST' },
  { tz: 'GMT-10:00', gmt: 'Pacific/Honolulu' },
  { tz: 'GMT-10:00', gmt: 'Pacific/Rarotonga' },
  { tz: 'GMT-10:00', gmt: 'Pacific/Tahiti' },
  { tz: 'GMT-09:30', gmt: 'Pacific/Marquesas' },
  { tz: 'GMT-09:00', gmt: 'America/Anchorage' },
  { tz: 'GMT-09:00', gmt: 'America/Juneau' },
  { tz: 'GMT-09:00', gmt: 'America/Nome' },
  { tz: 'GMT-09:00', gmt: 'America/Sitka' },
  { tz: 'GMT-09:00', gmt: 'America/Yakutat' },
  { tz: 'GMT-09:00', gmt: 'Etc/GMT+9' },
  { tz: 'GMT-09:00', gmt: 'Pacific/Gambier' },
  { tz: 'GMT-08:00', gmt: 'America/Los Angeles' },
  { tz: 'GMT-08:00', gmt: 'America/Tijuana' },
  { tz: 'GMT-08:00', gmt: 'America/Vancouver' },
  { tz: 'GMT-08:00', gmt: 'Etc/GMT+8' },
  { tz: 'GMT-08:00', gmt: 'PST8PDT' },
  { tz: 'GMT-08:00', gmt: 'Pacific/Pitcairn' },
  { tz: 'GMT-07:00', gmt: 'America/Boise' },
  { tz: 'GMT-07:00', gmt: 'America/Cambridge Bay' },
  { tz: 'GMT-07:00', gmt: 'America/Chihuahua' },
  { tz: 'GMT-07:00', gmt: 'America/Creston' },
  { tz: 'GMT-07:00', gmt: 'America/Dawson' },
  { tz: 'GMT-07:00', gmt: 'America/Dawson Creek' },
  { tz: 'GMT-07:00', gmt: 'America/Denver' },
  { tz: 'GMT-07:00', gmt: 'America/Edmonton' },
  { tz: 'GMT-07:00', gmt: 'America/Fort Nelson' },
  { tz: 'GMT-07:00', gmt: 'America/Hermosillo' },
  { tz: 'GMT-07:00', gmt: 'America/Inuvik' },
  { tz: 'GMT-07:00', gmt: 'America/Mazatlan' },
  { tz: 'GMT-07:00', gmt: 'America/Ojinaga' },
  { tz: 'GMT-07:00', gmt: 'America/Phoenix' },
  { tz: 'GMT-07:00', gmt: 'America/Whitehorse' },
  { tz: 'GMT-07:00', gmt: 'America/Yellowknife' },
  { tz: 'GMT-07:00', gmt: 'Etc/GMT+7' },
  { tz: 'GMT-07:00', gmt: 'MST' },
  { tz: 'GMT-07:00', gmt: 'MST7MDT' },
  { tz: 'GMT-06:00', gmt: 'America/Bahia Banderas' },
  { tz: 'GMT-06:00', gmt: 'America/Belize' },
  { tz: 'GMT-06:00', gmt: 'America/Chicago' },
  { tz: 'GMT-06:00', gmt: 'America/Costa Rica' },
  { tz: 'GMT-06:00', gmt: 'America/El Salvador' },
  { tz: 'GMT-06:00', gmt: 'America/Guatemala' },
  { tz: 'GMT-06:00', gmt: 'America/Indiana/Knox' },
  { tz: 'GMT-06:00', gmt: 'America/Indiana/Tell City' },
  { tz: 'GMT-06:00', gmt: 'America/Managua' },
  { tz: 'GMT-06:00', gmt: 'America/Matamoros' },
  { tz: 'GMT-06:00', gmt: 'America/Menominee' },
  { tz: 'GMT-06:00', gmt: 'America/Merida' },
  { tz: 'GMT-06:00', gmt: 'America/Mexico City' },
  { tz: 'GMT-06:00', gmt: 'America/Monterrey' },
  { tz: 'GMT-06:00', gmt: 'America/North Dakota/Beulah' },
  { tz: 'GMT-06:00', gmt: 'America/North Dakota/Center' },
  { tz: 'GMT-06:00', gmt: 'America/North Dakota/New_Salem' },
  { tz: 'GMT-06:00', gmt: 'America/Rainy River' },
  { tz: 'GMT-06:00', gmt: 'America/Rankin Inlet' },
  { tz: 'GMT-06:00', gmt: 'America/Regina' },
  { tz: 'GMT-06:00', gmt: 'America/Resolute' },
  { tz: 'GMT-06:00', gmt: 'America/Swift Current' },
  { tz: 'GMT-06:00', gmt: 'America/Tegucigalpa' },
  { tz: 'GMT-06:00', gmt: 'America/Winnipeg' },
  { tz: 'GMT-06:00', gmt: 'CST6CDT' },
  { tz: 'GMT-06:00', gmt: 'Etc/GMT+6' },
  { tz: 'GMT-06:00', gmt: 'Pacific/Galapagos' },
  { tz: 'GMT-05:00', gmt: 'America/Atikokan' },
  { tz: 'GMT-05:00', gmt: 'America/Bogota' },
  { tz: 'GMT-05:00', gmt: 'America/Cancun' },
  { tz: 'GMT-05:00', gmt: 'America/Cayman' },
  { tz: 'GMT-05:00', gmt: 'America/Detroit' },
  { tz: 'GMT-05:00', gmt: 'America/Eirunepe' },
  { tz: 'GMT-05:00', gmt: 'America/Grand Turk' },
  { tz: 'GMT-05:00', gmt: 'America/Guayaquil' },
  { tz: 'GMT-05:00', gmt: 'America/Havana' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Indianapolis' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Marengo' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Petersburg' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Vevay' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Vincennes' },
  { tz: 'GMT-05:00', gmt: 'America/Indiana/Winamac' },
  { tz: 'GMT-05:00', gmt: 'America/Indianapolis' },
  { tz: 'GMT-05:00', gmt: 'America/Iqaluit' },
  { tz: 'GMT-05:00', gmt: 'America/Jamaica' },
  { tz: 'GMT-05:00', gmt: 'America/Kentucky/Louisville' },
  { tz: 'GMT-05:00', gmt: 'America/Kentucky/Monticello' },
  { tz: 'GMT-05:00', gmt: 'America/Lima' },
  { tz: 'GMT-05:00', gmt: 'America/Montreal' },
  { tz: 'GMT-05:00', gmt: 'America/Nassau' },
  { tz: 'GMT-05:00', gmt: 'America/New York' },
  { tz: 'GMT-05:00', gmt: 'America/Nipigon' },
  { tz: 'GMT-05:00', gmt: 'America/Panama' },
  { tz: 'GMT-05:00', gmt: 'America/Pangnirtung' },
  { tz: 'GMT-05:00', gmt: 'America/Port-au-Prince' },
  { tz: 'GMT-05:00', gmt: 'America/Rio Branco' },
  { tz: 'GMT-05:00', gmt: 'America/Thunder Bay' },
  { tz: 'GMT-05:00', gmt: 'America/Toronto' },
  { tz: 'GMT-05:00', gmt: 'EST' },
  { tz: 'GMT-05:00', gmt: 'EST5EDT' },
  { tz: 'GMT-05:00', gmt: 'Etc/GMT+5' },
  { tz: 'GMT-05:00', gmt: 'Pacific/Easter' },
  { tz: 'GMT-04:00', gmt: 'America/Anguilla' },
  { tz: 'GMT-04:00', gmt: 'America/Antigua' },
  { tz: 'GMT-04:00', gmt: 'America/Aruba' },
  { tz: 'GMT-04:00', gmt: 'America/Barbados' },
  { tz: 'GMT-04:00', gmt: 'America/Blanc-Sablon' },
  { tz: 'GMT-04:00', gmt: 'America/Boa Vista' },
  { tz: 'GMT-04:00', gmt: 'America/Campo Grande' },
  { tz: 'GMT-04:00', gmt: 'America/Caracas' },
  { tz: 'GMT-04:00', gmt: 'America/Cuiaba' },
  { tz: 'GMT-04:00', gmt: 'America/Curacao' },
  { tz: 'GMT-04:00', gmt: 'America/Dominica' },
  { tz: 'GMT-04:00', gmt: 'America/Glace Bay' },
  { tz: 'GMT-04:00', gmt: 'America/Goose Bay' },
  { tz: 'GMT-04:00', gmt: 'America/Grenada' },
  { tz: 'GMT-04:00', gmt: 'America/Guadeloupe' },
  { tz: 'GMT-04:00', gmt: 'America/Guyana' },
  { tz: 'GMT-04:00', gmt: 'America/Halifax' },
  { tz: 'GMT-04:00', gmt: 'America/Kralendijk' },
  { tz: 'GMT-04:00', gmt: 'America/La Paz' },
  { tz: 'GMT-04:00', gmt: 'America/Lower Princes' },
  { tz: 'GMT-04:00', gmt: 'America/Manaus' },
  { tz: 'GMT-04:00', gmt: 'America/Marigot' },
  { tz: 'GMT-04:00', gmt: 'America/Martinique' },
  { tz: 'GMT-04:00', gmt: 'America/Moncton' },
  { tz: 'GMT-04:00', gmt: 'America/Montserrat' },
  { tz: 'GMT-04:00', gmt: 'America/Port of_Spain' },
  { tz: 'GMT-04:00', gmt: 'America/Porto Velho' },
  { tz: 'GMT-04:00', gmt: 'America/Puerto Rico' },
  { tz: 'GMT-04:00', gmt: 'America/Santo Domingo' },
  { tz: 'GMT-04:00', gmt: 'America/St Barthelemy' },
  { tz: 'GMT-04:00', gmt: 'America/St Kitts' },
  { tz: 'GMT-04:00', gmt: 'America/St Lucia' },
  { tz: 'GMT-04:00', gmt: 'America/St Thomas' },
  { tz: 'GMT-04:00', gmt: 'America/St Vincent' },
  { tz: 'GMT-04:00', gmt: 'America/Thule' },
  { tz: 'GMT-04:00', gmt: 'America/Tortola' },
  { tz: 'GMT-04:00', gmt: 'Atlantic/Bermuda' },
  { tz: 'GMT-04:00', gmt: 'Etc/GMT+4' },
  { tz: 'GMT-03:30', gmt: 'America/St Johns' },
  { tz: 'GMT-03:00', gmt: 'America/Araguaina' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Buenos Aires' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Catamarca' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Cordoba' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Jujuy' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/La Rioja' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Mendoza' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Rio Gallegos' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Salta' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/San Juan' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/San Luis' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Tucuman' },
  { tz: 'GMT-03:00', gmt: 'America/Argentina/Ushuaia' },
  { tz: 'GMT-03:00', gmt: 'America/Asuncion' },
  { tz: 'GMT-03:00', gmt: 'America/Bahia' },
  { tz: 'GMT-03:00', gmt: 'America/Belem' },
  { tz: 'GMT-03:00', gmt: 'America/Cayenne' },
  { tz: 'GMT-03:00', gmt: 'America/Fortaleza' },
  { tz: 'GMT-03:00', gmt: 'America/Godthab' },
  { tz: 'GMT-03:00', gmt: 'America/Maceio' },
  { tz: 'GMT-03:00', gmt: 'America/Miquelon' },
  { tz: 'GMT-03:00', gmt: 'America/Montevideo' },
  { tz: 'GMT-03:00', gmt: 'America/Nuuk' },
  { tz: 'GMT-03:00', gmt: 'America/Paramaribo' },
  { tz: 'GMT-03:00', gmt: 'America/Punta Arenas' },
  { tz: 'GMT-03:00', gmt: 'America/Recife' },
  { tz: 'GMT-03:00', gmt: 'America/Santarem' },
  { tz: 'GMT-03:00', gmt: 'America/Santiago' },
  { tz: 'GMT-03:00', gmt: 'America/Sao Paulo' },
  { tz: 'GMT-03:00', gmt: 'Antarctica/Palmer' },
  { tz: 'GMT-03:00', gmt: 'Antarctica/Rothera' },
  { tz: 'GMT-03:00', gmt: 'Atlantic/Stanley' },
  { tz: 'GMT-03:00', gmt: 'Etc/GMT+3' },
  { tz: 'GMT-02:00', gmt: 'America/Noronha' },
  { tz: 'GMT-02:00', gmt: 'Atlantic/South Georgia' },
  { tz: 'GMT-02:00', gmt: 'Etc/GMT+2' },
  { tz: 'GMT-01:00', gmt: 'America/Scoresbysund' },
  { tz: 'GMT-01:00', gmt: 'Atlantic/Azores' },
  { tz: 'GMT-01:00', gmt: 'Atlantic/Cape Verde' },
  { tz: 'GMT-01:00', gmt: 'Etc/GMT+1' },
  { tz: 'GMT+00:00', gmt: 'Africa/Abidjan' },
  { tz: 'GMT+00:00', gmt: 'Africa/Accra' },
  { tz: 'GMT+00:00', gmt: 'Africa/Bamako' },
  { tz: 'GMT+00:00', gmt: 'Africa/Banjul' },
  { tz: 'GMT+00:00', gmt: 'Africa/Bissau' },
  { tz: 'GMT+00:00', gmt: 'Africa/Conakry' },
  { tz: 'GMT+00:00', gmt: 'Africa/Dakar' },
  { tz: 'GMT+00:00', gmt: 'Africa/Freetown' },
  { tz: 'GMT+00:00', gmt: 'Africa/Lome' },
  { tz: 'GMT+00:00', gmt: 'Africa/Monrovia' },
  { tz: 'GMT+00:00', gmt: 'Africa/Nouakchott' },
  { tz: 'GMT+00:00', gmt: 'Africa/Ouagadougou' },
  { tz: 'GMT+00:00', gmt: 'Africa/Sao Tome' },
  { tz: 'GMT+00:00', gmt: 'America/Danmarkshavn' },
  { tz: 'GMT+00:00', gmt: 'Antarctica/Troll' },
  { tz: 'GMT+00:00', gmt: 'Atlantic/Canary' },
  { tz: 'GMT+00:00', gmt: 'Atlantic/Faroe' },
  { tz: 'GMT+00:00', gmt: 'Atlantic/Madeira' },
  { tz: 'GMT+00:00', gmt: 'Atlantic/Reykjavik' },
  { tz: 'GMT+00:00', gmt: 'Atlantic/St Helena' },
  { tz: 'GMT+00:00', gmt: 'Etc/GMT' },
  { tz: 'GMT+00:00', gmt: 'Etc/GMT+0' },
  { tz: 'GMT+00:00', gmt: 'Etc/GMT-0' },
  { tz: 'GMT+00:00', gmt: 'Etc/GMT0' },
  { tz: 'GMT+00:00', gmt: 'Etc/Greenwich' },
  { tz: 'GMT+00:00', gmt: 'Etc/Universal' },
  { tz: 'GMT+00:00', gmt: 'Etc/Zulu' },
  { tz: 'GMT+00:00', gmt: 'Europe/Dublin' },
  { tz: 'GMT+00:00', gmt: 'Europe/Guernsey' },
  { tz: 'GMT+00:00', gmt: 'Europe/Isle of_Man' },
  { tz: 'GMT+00:00', gmt: 'Europe/Jersey' },
  { tz: 'GMT+00:00', gmt: 'Europe/Lisbon' },
  { tz: 'GMT+00:00', gmt: 'Europe/London' },
  { tz: 'GMT+00:00', gmt: 'GMT' },
  { tz: 'GMT+00:00', gmt: 'UTC' },
  { tz: 'GMT+00:00', gmt: 'WET' },
  { tz: 'GMT+01:00', gmt: 'Africa/Algiers' },
  { tz: 'GMT+01:00', gmt: 'Africa/Bangui' },
  { tz: 'GMT+01:00', gmt: 'Africa/Brazzaville' },
  { tz: 'GMT+01:00', gmt: 'Africa/Casablanca' },
  { tz: 'GMT+01:00', gmt: 'Africa/Ceuta' },
  { tz: 'GMT+01:00', gmt: 'Africa/Douala' },
  { tz: 'GMT+01:00', gmt: 'Africa/El Aaiun' },
  { tz: 'GMT+01:00', gmt: 'Africa/Kinshasa' },
  { tz: 'GMT+01:00', gmt: 'Africa/Lagos' },
  { tz: 'GMT+01:00', gmt: 'Africa/Libreville' },
  { tz: 'GMT+01:00', gmt: 'Africa/Luanda' },
  { tz: 'GMT+01:00', gmt: 'Africa/Malabo' },
  { tz: 'GMT+01:00', gmt: 'Africa/Ndjamena' },
  { tz: 'GMT+01:00', gmt: 'Africa/Niamey' },
  { tz: 'GMT+01:00', gmt: 'Africa/Porto-Novo' },
  { tz: 'GMT+01:00', gmt: 'Africa/Tunis' },
  { tz: 'GMT+01:00', gmt: 'Arctic/Longyearbyen' },
  { tz: 'GMT+01:00', gmt: 'CET' },
  { tz: 'GMT+01:00', gmt: 'Etc/GMT-1' },
  { tz: 'GMT+01:00', gmt: 'Europe/Amsterdam' },
  { tz: 'GMT+01:00', gmt: 'Europe/Andorra' },
  { tz: 'GMT+01:00', gmt: 'Europe/Belgrade' },
  { tz: 'GMT+01:00', gmt: 'Europe/Berlin' },
  { tz: 'GMT+01:00', gmt: 'Europe/Bratislava' },
  { tz: 'GMT+01:00', gmt: 'Europe/Brussels' },
  { tz: 'GMT+01:00', gmt: 'Europe/Budapest' },
  { tz: 'GMT+01:00', gmt: 'Europe/Busingen' },
  { tz: 'GMT+01:00', gmt: 'Europe/Copenhagen' },
  { tz: 'GMT+01:00', gmt: 'Europe/Gibraltar' },
  { tz: 'GMT+01:00', gmt: 'Europe/Ljubljana' },
  { tz: 'GMT+01:00', gmt: 'Europe/Luxembourg' },
  { tz: 'GMT+01:00', gmt: 'Europe/Madrid' },
  { tz: 'GMT+01:00', gmt: 'Europe/Malta' },
  { tz: 'GMT+01:00', gmt: 'Europe/Monaco' },
  { tz: 'GMT+01:00', gmt: 'Europe/Oslo' },
  { tz: 'GMT+01:00', gmt: 'Europe/Paris' },
  { tz: 'GMT+01:00', gmt: 'Europe/Podgorica' },
  { tz: 'GMT+01:00', gmt: 'Europe/Prague' },
  { tz: 'GMT+01:00', gmt: 'Europe/Rome' },
  { tz: 'GMT+01:00', gmt: 'Europe/San Marino' },
  { tz: 'GMT+01:00', gmt: 'Europe/Sarajevo' },
  { tz: 'GMT+01:00', gmt: 'Europe/Skopje' },
  { tz: 'GMT+01:00', gmt: 'Europe/Stockholm' },
  { tz: 'GMT+01:00', gmt: 'Europe/Tirane' },
  { tz: 'GMT+01:00', gmt: 'Europe/Vaduz' },
  { tz: 'GMT+01:00', gmt: 'Europe/Vatican' },
  { tz: 'GMT+01:00', gmt: 'Europe/Vienna' },
  { tz: 'GMT+01:00', gmt: 'Europe/Warsaw' },
  { tz: 'GMT+01:00', gmt: 'Europe/Zagreb' },
  { tz: 'GMT+01:00', gmt: 'Europe/Zurich' },
  { tz: 'GMT+01:00', gmt: 'MET' },
  { tz: 'GMT+02:00', gmt: 'Africa/Blantyre' },
  { tz: 'GMT+02:00', gmt: 'Africa/Bujumbura' },
  { tz: 'GMT+02:00', gmt: 'Africa/Cairo' },
  { tz: 'GMT+02:00', gmt: 'Africa/Gaborone' },
  { tz: 'GMT+02:00', gmt: 'Africa/Harare' },
  { tz: 'GMT+02:00', gmt: 'Africa/Johannesburg' },
  { tz: 'GMT+02:00', gmt: 'Africa/Khartoum' },
  { tz: 'GMT+02:00', gmt: 'Africa/Kigali' },
  { tz: 'GMT+02:00', gmt: 'Africa/Lubumbashi' },
  { tz: 'GMT+02:00', gmt: 'Africa/Lusaka' },
  { tz: 'GMT+02:00', gmt: 'Africa/Maputo' },
  { tz: 'GMT+02:00', gmt: 'Africa/Maseru' },
  { tz: 'GMT+02:00', gmt: 'Africa/Mbabane' },
  { tz: 'GMT+02:00', gmt: 'Africa/Tripoli' },
  { tz: 'GMT+02:00', gmt: 'Africa/Windhoek' },
  { tz: 'GMT+02:00', gmt: 'Asia/Amman' },
  { tz: 'GMT+02:00', gmt: 'Asia/Beirut' },
  { tz: 'GMT+02:00', gmt: 'Asia/Damascus' },
  { tz: 'GMT+02:00', gmt: 'Asia/Famagusta' },
  { tz: 'GMT+02:00', gmt: 'Asia/Gaza' },
  { tz: 'GMT+02:00', gmt: 'Asia/Hebron' },
  { tz: 'GMT+02:00', gmt: 'Asia/Jerusalem' },
  { tz: 'GMT+02:00', gmt: 'Asia/Nicosia' },
  { tz: 'GMT+02:00', gmt: 'EET' },
  { tz: 'GMT+02:00', gmt: 'Etc/GMT-2' },
  { tz: 'GMT+02:00', gmt: 'Europe/Athens' },
  { tz: 'GMT+02:00', gmt: 'Europe/Bucharest' },
  { tz: 'GMT+02:00', gmt: 'Europe/Chisinau' },
  { tz: 'GMT+02:00', gmt: 'Europe/Helsinki' },
  { tz: 'GMT+02:00', gmt: 'Europe/Kaliningrad' },
  { tz: 'GMT+02:00', gmt: 'Europe/Kiev' },
  { tz: 'GMT+02:00', gmt: 'Europe/Mariehamn' },
  { tz: 'GMT+02:00', gmt: 'Europe/Nicosia' },
  { tz: 'GMT+02:00', gmt: 'Europe/Riga' },
  { tz: 'GMT+02:00', gmt: 'Europe/Sofia' },
  { tz: 'GMT+02:00', gmt: 'Europe/Tallinn' },
  { tz: 'GMT+02:00', gmt: 'Europe/Uzhgorod' },
  { tz: 'GMT+02:00', gmt: 'Europe/Vilnius' },
  { tz: 'GMT+02:00', gmt: 'Europe/Zaporozhye' },
  { tz: 'GMT+03:00', gmt: 'Africa/Addis Ababa' },
  { tz: 'GMT+03:00', gmt: 'Africa/Asmara' },
  { tz: 'GMT+03:00', gmt: 'Africa/Dar es_Salaam' },
  { tz: 'GMT+03:00', gmt: 'Africa/Djibouti' },
  { tz: 'GMT+03:00', gmt: 'Africa/Juba' },
  { tz: 'GMT+03:00', gmt: 'Africa/Kampala' },
  { tz: 'GMT+03:00', gmt: 'Africa/Mogadishu' },
  { tz: 'GMT+03:00', gmt: 'Africa/Nairobi' },
  { tz: 'GMT+03:00', gmt: 'Antarctica/Syowa' },
  { tz: 'GMT+03:00', gmt: 'Asia/Aden' },
  { tz: 'GMT+03:00', gmt: 'Asia/Baghdad' },
  { tz: 'GMT+03:00', gmt: 'Asia/Bahrain' },
  { tz: 'GMT+03:00', gmt: 'Asia/Istanbul' },
  { tz: 'GMT+03:00', gmt: 'Asia/Kuwait' },
  { tz: 'GMT+03:00', gmt: 'Asia/Qatar' },
  { tz: 'GMT+03:00', gmt: 'Asia/Riyadh' },
  { tz: 'GMT+03:00', gmt: 'Etc/GMT-3' },
  { tz: 'GMT+03:00', gmt: 'Europe/Istanbul' },
  { tz: 'GMT+03:00', gmt: 'Europe/Kirov' },
  { tz: 'GMT+03:00', gmt: 'Europe/Minsk' },
  { tz: 'GMT+03:00', gmt: 'Europe/Moscow' },
  { tz: 'GMT+03:00', gmt: 'Europe/Simferopol' },
  { tz: 'GMT+03:00', gmt: 'Indian/Antananarivo' },
  { tz: 'GMT+03:00', gmt: 'Indian/Comoro' },
  { tz: 'GMT+03:00', gmt: 'Indian/Mayotte' },
  { tz: 'GMT+03:30', gmt: 'Asia/Tehran' },
  { tz: 'GMT+04:00', gmt: 'Asia/Baku' },
  { tz: 'GMT+04:00', gmt: 'Asia/Dubai' },
  { tz: 'GMT+04:00', gmt: 'Asia/Muscat' },
  { tz: 'GMT+04:00', gmt: 'Asia/Tbilisi' },
  { tz: 'GMT+04:00', gmt: 'Asia/Yerevan' },
  { tz: 'GMT+04:00', gmt: 'Etc/GMT-4' },
  { tz: 'GMT+04:00', gmt: 'Europe/Astrakhan' },
  { tz: 'GMT+04:00', gmt: 'Europe/Samara' },
  { tz: 'GMT+04:00', gmt: 'Europe/Saratov' },
  { tz: 'GMT+04:00', gmt: 'Europe/Ulyanovsk' },
  { tz: 'GMT+04:00', gmt: 'Europe/Volgograd' },
  { tz: 'GMT+04:00', gmt: 'Indian/Mahe' },
  { tz: 'GMT+04:00', gmt: 'Indian/Mauritius' },
  { tz: 'GMT+04:00', gmt: 'Indian/Reunion' },
  { tz: 'GMT+04:30', gmt: 'Asia/Kabul' },
  { tz: 'GMT+05:00', gmt: 'Antarctica/Mawson' },
  { tz: 'GMT+05:00', gmt: 'Asia/Aqtau' },
  { tz: 'GMT+05:00', gmt: 'Asia/Aqtobe' },
  { tz: 'GMT+05:00', gmt: 'Asia/Ashgabat' },
  { tz: 'GMT+05:00', gmt: 'Asia/Atyrau' },
  { tz: 'GMT+05:00', gmt: 'Asia/Dushanbe' },
  { tz: 'GMT+05:00', gmt: 'Asia/Karachi' },
  { tz: 'GMT+05:00', gmt: 'Asia/Oral' },
  { tz: 'GMT+05:00', gmt: 'Asia/Qyzylorda' },
  { tz: 'GMT+05:00', gmt: 'Asia/Samarkand' },
  { tz: 'GMT+05:00', gmt: 'Asia/Tashkent' },
  { tz: 'GMT+05:00', gmt: 'Asia/Yekaterinburg' },
  { tz: 'GMT+05:00', gmt: 'Etc/GMT-5' },
  { tz: 'GMT+05:00', gmt: 'Indian/Kerguelen' },
  { tz: 'GMT+05:00', gmt: 'Indian/Maldives' },
  { tz: 'GMT+05:30', gmt: 'Asia/Calcutta' },
  { tz: 'GMT+05:30', gmt: 'Asia/Colombo' },
  { tz: 'GMT+05:30', gmt: 'Asia/Kolkata' },
  { tz: 'GMT+05:45', gmt: 'Asia/Kathmandu' },
  { tz: 'GMT+05:45', gmt: 'Asia/Katmandu' },
  { tz: 'GMT+06:00', gmt: 'Antarctica/Vostok' },
  { tz: 'GMT+06:00', gmt: 'Asia/Almaty' },
  { tz: 'GMT+06:00', gmt: 'Asia/Bishkek' },
  { tz: 'GMT+06:00', gmt: 'Asia/Dhaka' },
  { tz: 'GMT+06:00', gmt: 'Asia/Omsk' },
  { tz: 'GMT+06:00', gmt: 'Asia/Qostanay' },
  { tz: 'GMT+06:00', gmt: 'Asia/Thimphu' },
  { tz: 'GMT+06:00', gmt: 'Asia/Urumqi' },
  { tz: 'GMT+06:00', gmt: 'Etc/GMT-6' },
  { tz: 'GMT+06:00', gmt: 'Indian/Chagos' },
  { tz: 'GMT+06:30', gmt: 'Asia/Yangon' },
  { tz: 'GMT+06:30', gmt: 'Indian/Cocos' },
  { tz: 'GMT+07:00', gmt: 'Antarctica/Davis' },
  { tz: 'GMT+07:00', gmt: 'Asia/Bangkok' },
  { tz: 'GMT+07:00', gmt: 'Asia/Barnaul' },
  { tz: 'GMT+07:00', gmt: 'Asia/Ho Chi_Minh' },
  { tz: 'GMT+07:00', gmt: 'Asia/Hovd' },
  { tz: 'GMT+07:00', gmt: 'Asia/Jakarta' },
  { tz: 'GMT+07:00', gmt: 'Asia/Krasnoyarsk' },
  { tz: 'GMT+07:00', gmt: 'Asia/Novokuznetsk' },
  { tz: 'GMT+07:00', gmt: 'Asia/Novosibirsk' },
  { tz: 'GMT+07:00', gmt: 'Asia/Phnom Penh' },
  { tz: 'GMT+07:00', gmt: 'Asia/Pontianak' },
  { tz: 'GMT+07:00', gmt: 'Asia/Tomsk' },
  { tz: 'GMT+07:00', gmt: 'Asia/Vientiane' },
  { tz: 'GMT+07:00', gmt: 'Etc/GMT-7' },
  { tz: 'GMT+07:00', gmt: 'Indian/Christmas' },
  { tz: 'GMT+08:00', gmt: 'Asia/Brunei' },
  { tz: 'GMT+08:00', gmt: 'Asia/Choibalsan' },
  { tz: 'GMT+08:00', gmt: 'Asia/Hong Kong' },
  { tz: 'GMT+08:00', gmt: 'Asia/Irkutsk' },
  { tz: 'GMT+08:00', gmt: 'Asia/Kuala Lumpur' },
  { tz: 'GMT+08:00', gmt: 'Asia/Kuching' },
  { tz: 'GMT+08:00', gmt: 'Asia/Macau' },
  { tz: 'GMT+08:00', gmt: 'Asia/Makassar' },
  { tz: 'GMT+08:00', gmt: 'Asia/Manila' },
  { tz: 'GMT+08:00', gmt: 'Asia/Shanghai' },
  { tz: 'GMT+08:00', gmt: 'Asia/Singapore' },
  { tz: 'GMT+08:00', gmt: 'Asia/Taipei' },
  { tz: 'GMT+08:00', gmt: 'Asia/Ulaanbaatar' },
  { tz: 'GMT+08:00', gmt: 'Australia/Perth' },
  { tz: 'GMT+08:00', gmt: 'Etc/GMT-8' },
  { tz: 'GMT+08:45', gmt: 'Australia/Eucla' },
  { tz: 'GMT+09:00', gmt: 'Asia/Chita' },
  { tz: 'GMT+09:00', gmt: 'Asia/Dili' },
  { tz: 'GMT+09:00', gmt: 'Asia/Jayapura' },
  { tz: 'GMT+09:00', gmt: 'Asia/Khandyga' },
  { tz: 'GMT+09:00', gmt: 'Asia/Pyongyang' },
  { tz: 'GMT+09:00', gmt: 'Asia/Seoul' },
  { tz: 'GMT+09:00', gmt: 'Asia/Tokyo' },
  { tz: 'GMT+09:00', gmt: 'Asia/Yakutsk' },
  { tz: 'GMT+09:00', gmt: 'Etc/GMT-9' },
  { tz: 'GMT+09:00', gmt: 'Pacific/Palau' },
  { tz: 'GMT+09:30', gmt: 'Australia/Darwin' },
  { tz: 'GMT+10:00', gmt: 'Antarctica/DumontDUrville' },
  { tz: 'GMT+10:00', gmt: 'Asia/Ust-Nera' },
  { tz: 'GMT+10:00', gmt: 'Asia/Vladivostok' },
  { tz: 'GMT+10:00', gmt: 'Australia/Brisbane' },
  { tz: 'GMT+10:00', gmt: 'Australia/Lindeman' },
  { tz: 'GMT+10:00', gmt: 'Etc/GMT-10' },
  { tz: 'GMT+10:00', gmt: 'Pacific/Chuuk' },
  { tz: 'GMT+10:00', gmt: 'Pacific/Guam' },
  { tz: 'GMT+10:00', gmt: 'Pacific/Port Moresby' },
  { tz: 'GMT+10:00', gmt: 'Pacific/Saipan' },
  { tz: 'GMT+10:30', gmt: 'Australia/Adelaide' },
  { tz: 'GMT+10:30', gmt: 'Australia/Broken Hill' },
  { tz: 'GMT+11:00', gmt: 'Antarctica/Casey' },
  { tz: 'GMT+11:00', gmt: 'Antarctica/Macquarie' },
  { tz: 'GMT+11:00', gmt: 'Asia/Magadan' },
  { tz: 'GMT+11:00', gmt: 'Asia/Sakhalin' },
  { tz: 'GMT+11:00', gmt: 'Asia/Srednekolymsk' },
  { tz: 'GMT+11:00', gmt: 'Australia/Currie' },
  { tz: 'GMT+11:00', gmt: 'Australia/Hobart' },
  { tz: 'GMT+11:00', gmt: 'Australia/Lord Howe' },
  { tz: 'GMT+11:00', gmt: 'Australia/Melbourne' },
  { tz: 'GMT+11:00', gmt: 'Australia/Sydney' },
  { tz: 'GMT+11:00', gmt: 'Etc/GMT-11' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Bougainville' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Efate' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Guadalcanal' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Kosrae' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Noumea' },
  { tz: 'GMT+11:00', gmt: 'Pacific/Pohnpei' },
  { tz: 'GMT+12:00', gmt: 'Asia/Anadyr' },
  { tz: 'GMT+12:00', gmt: 'Asia/Kamchatka' },
  { tz: 'GMT+12:00', gmt: 'Etc/GMT-12' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Fiji' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Funafuti' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Kwajalein' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Majuro' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Nauru' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Norfolk' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Tarawa' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Wake' },
  { tz: 'GMT+12:00', gmt: 'Pacific/Wallis' },
  { tz: 'GMT+13:00', gmt: 'Antarctica/McMurdo' },
  { tz: 'GMT+13:00', gmt: 'Etc/GMT-13' },
  { tz: 'GMT+13:00', gmt: 'Pacific/Auckland' },
  { tz: 'GMT+13:00', gmt: 'Pacific/Enderbury' },
  { tz: 'GMT+13:00', gmt: 'Pacific/Fakaofo' },
  { tz: 'GMT+13:00', gmt: 'Pacific/Tongatapu' },
  { tz: 'GMT+13:45', gmt: 'Pacific/Chatham' },
  { tz: 'GMT+14:00', gmt: 'Etc/GMT-14' },
  { tz: 'GMT+14:00', gmt: 'Pacific/Apia' },
  { tz: 'GMT+14:00', gmt: 'Pacific/Kiritimati' }
]

export const getLocalTimeZone = () => {
  // 如果存在于列表中则拿列表中的，如果不存在则取同时区的第一个时区
  const localGmt: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localZt: string = moment(new Date()).format('Z')

  const list: any[] = getTimeZone()

  const checkItem = list.filter((i: any) => i.gmt === localGmt)

  if (checkItem.length > 0) {
    // 取出匹配的
    return {
      tz: localZt,
      gmt: localGmt
    }
  }
  // 如果没有匹配上则取出同时区中的第一个
  const gmts: any[] = list.filter((i: any) => i.tz.indexOf(localZt) >= 0)

  if (gmts && gmts.length > 0) {
    return {
      tz: localZt,
      gmt: gmts[0].gmt
    }
  }
  // 如果都没有匹配则返回默认时区
  return { tz: '+00:00', gmt: 'Europe/London' }
}
