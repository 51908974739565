/*
 * @FilePath: /lark-activity/src/utils/index.ts
 * @Description:
 */

export const bin2hex = (s: string): string => {
  let n = ''
  let o: any = ''
  s += ''
  for (let i = 0; i < s.length; i += 1) {
    n = s.charCodeAt(i).toString(16)
    o += n.length < 2 ? `0${n}` : n
  }
  return o
}

export const getCanvasFingerprint = (): string => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as any
  const txt = 'yunlark.net'
  ctx.textBaseline = 'top'
  ctx.font = '14px "Arial"'
  ctx.textBaseline = 'tencent'
  ctx.fillStyle = '#f60'
  ctx.fillRect(125, 1, 62, 20)
  ctx.fillStyle = '#069'
  ctx.fillText(txt, 2, 15)
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
  ctx.fillText(txt, 4, 17)

  const b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
  const bin = atob(b64)
  const crc = bin2hex(bin.slice(-16, -12))
  return crc
}

// 将编码转换成字符
export const utf8ToChar = (str: string): string => {
  const iCode = parseInt(`0x${str.substr(1, 2)}`, 16)
  const iCode1 = parseInt(`0x${str.substr(4, 2)}`, 16)
  const iCode2 = parseInt(`0x${str.substr(7, 2)}`, 16)
  // tslint:disable-next-line: no-bitwise
  return String.fromCharCode(((iCode & 0x0F) << 12) | ((iCode1 & 0x3F) << 6) | (iCode2 & 0x3F))
}

export const getCharFromUtf8 = (str: string): string => {
  let cstr = ''
  let nOffset = 0
  if (str === '') {
    return ''
  }
  str = str.toLowerCase()
  nOffset = str.indexOf('%e')
  if (nOffset === -1) {
    return str
  }
  while (nOffset !== -1) {
    cstr += str.substr(0, nOffset)
    str = str.substr(nOffset, str.length - nOffset)
    if (str === '' || str.length < 9) {
      return cstr
    }
    cstr += utf8ToChar(str.substr(0, 9))
    str = str.substr(9, str.length - 9)
    nOffset = str.indexOf('%e')
  }
  return cstr + str
}

// 获取 url 的参数
export const getQueryString = (name: string, url?: string): string| null => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  let newUrl = ''
  if (url) {
    const index = url.indexOf('?')
    newUrl = url.substr(index)
  } else {
    newUrl = window.location.search
  }
  const r = newUrl.substr(1).match(reg)
  if (r != null) { return unescape(getCharFromUtf8(r[2])) } return null
}
