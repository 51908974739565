import { render, staticRenderFns } from "./Trait.vue?vue&type=template&id=16a0477e&scoped=true&"
import script from "./Trait.vue?vue&type=script&lang=ts&"
export * from "./Trait.vue?vue&type=script&lang=ts&"
import style0 from "./Trait.vue?vue&type=style&index=0&id=16a0477e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a0477e",
  null
  
)

export default component.exports