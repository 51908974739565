




























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Superiority extends Vue {
  items= [
    {
      id: 0,
      title: '数据加密传输',
      content: '不会发送隐藏请求和跟踪不必要的信息',
      icon: 'icon-HTTPS'
    },
    {
      id: 1,
      title: '超快访问速度',
      content: '服务器全球化节点部署，为用户提供快速响应的访问',
      icon: 'icon-kuaisu_jiasu'
    },
    {
      id: 2,
      title: '支持个性化定制',
      content: '满足企业个性化的需求，为您打造独一无二的定制方案',
      icon: 'icon-yonghushezhi'
    },
    {
      id: 3,
      title: '专属优质客服',
      content: '快速响应，解决每位客户的问题，帮助提高您的工作效率',
      icon: 'icon-kefu'
    }
  ]
}
