





import ReferrerRelayUtil from './utils/referrerRelayUtil'

import { Component, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue {
  created (): void {
    // eslint-disable-next-line no-new
    new ReferrerRelayUtil().saveItem()
  }
}
