
window.addEventListener('load', () => {
  // 出场动画
  const anim = document.querySelectorAll('.anim')
  setTimeout(function () {
    // 规避页面加载不完整高度获取异常

    window.addEventListener('scroll', () => {
      roll()
    })

    window.addEventListener('resize', () => {
      roll()
    })

    roll()
  }, 6)
  function roll () { // 滚动执行动画
    const oHeight: any = window.innerHeight
    const ScrVal: any = window.scrollY
    for (let index = 0; index < anim.length; index++) {
      const element: any = anim[index]
      if (ScrVal + oHeight > element.offsetTop + 100) {
        element.classList.add('anim-show')
      }
    }
  }
})
