/*
 * @FilePath: /lark-activity/src/lib/axios.ts
 * @Description:
 */

import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'development' ? 'http://api.yunlark.com:8081/' : 'https://lark-api.yunlark.com/'

const $axios = axios.create({
  baseURL,
  transformRequest: [
    (oldData: any) => {
      let newStr = ''
      if (oldData) {
        Object.keys(oldData).map((item) => {
          if (item) {
            newStr += `${encodeURIComponent(item)}=${encodeURIComponent(
                        oldData[item]
                    )}&`
          }
          return newStr
        })
        newStr = newStr.slice(0, -1)
      }
      return newStr
    }
  ]
})

export default $axios
