

























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Trait extends Vue {
  items = [
    {
      id: 0,
      title: '云雀自动化（新功能）',
      content: '自动化科技，支持所有跨境平台；简单灵活可定制，适用各种场景；释放低效重复劳动，赋能企业创造更高价值'
    },
    {
      id: 1,
      title: '同时启动N+个电脑',
      content: '在Yunlark中创建的每一个配置都是完全独立的虚拟工具环境平台，不必再开N+个虚拟机了！'
    },
    {
      id: 2,
      title: '代理服务商多选',
      content: '支持市面上主流的代理服务商，支持多种代理类型！'
    },
    {
      id: 3,
      title: '多平台多账号管理',
      content: '集中管理各电商、广告营销网站平台及账号，各网站环境之间相互隔离、访问不受限！'
    },
    {
      id: 4,
      title: '团队高效协同',
      content: '管理员—成员（子账号）分级授权管理模式，账号权限灵活分配，数据实时同步云端管理。'
    },
    {
      id: 5,
      title: '全面、快速、真实',
      content: '系统内置环境自动匹配机制， 基于您的真实IP为你匹配对应时区、语言、地理经纬度'
    }
  ]
}
