/*
 * @FilePath: /lark-activity/src/api/passport.ts
 * @Description:
 */

import axios from '@/lib/axios'
import { Params } from '@/type'

import { getCanvasFingerprint } from '@/utils'

// 注册
export const register = ({
  name,
  email,
  phone,
  password,
  code,
  inviteCode,
  language,
  source,
  timeZone,
  uniqueId,
  referer,
  refererUri
}: Params) => {
  const data: any = {}
  if (name) {
    data.name = name
  }
  if (email) {
    data.email = email
  }
  if (phone) {
    data.phone = phone
  }
  if (password) {
    data.password = password
  }
  if (code) {
    data.code = code
  }
  if (inviteCode) {
    data.invite_code = inviteCode
  }
  if (language) {
    data.language = language
  }
  if (source) {
    data.source = source
  }
  if (timeZone) {
    data.time_zone = timeZone
  }
  if (referer) {
    data.referer = referer
  }
  const mac = getCanvasFingerprint()
  if (mac) {
    data.c_id = mac
  }
  if (uniqueId) {
    data.unique_id = uniqueId
  }

  if (refererUri) {
    data.referer_uri = refererUri
  }

  return axios.request({
    url: 'sys/user/passport/register',
    data,
    method: 'post'
  })
}

// 发送手机注册验证码
export const sendRegMessage = (phone: number | string): Promise<void> => {
  const data = {
    phone
  }

  return axios.request({
    url: 'sys/user/passport/send-reg-phone',
    data,
    method: 'post'
  })
}
