






















import { Component, Vue } from 'vue-property-decorator'
import BaseHeader from '@/components/BaseHeader.vue'
import MainBody from './components/MainBody.vue'
import Trait from './components/Trait.vue'
import Service from './components/Service.vue'
import Superiority from './components/Superiority.vue'

@Component({
  components: {
    BaseHeader,
    MainBody,
    Trait,
    Service,
    Superiority
  }
})
export default class Home extends Vue {
  onChange (current: number): void {
    console.log(current)
  }
}
