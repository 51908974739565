













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class VerificationCode extends Vue {
    @Prop({
      default: 60
    })
    private seconds!:number | string

    @Prop({
      default: 'default'
    })
    size!: string

    private CountDown = 0

    private timer: any = null;

    private status = 0

    private startText = '获取验证码'

    private endText = '重新获取'

    get changeText (): string {
      return `${this.CountDown}s后重发`
    }

    get tipsText (): string {
      const results: any = {
        0: this.startText,
        1: this.changeText,
        2: this.endText
      }

      return results[this.status]
    }

    get isRunning (): boolean {
      return this.status === 1
    }

    private clearTimer () {
      clearInterval(this.timer)
    }

    start (): void{
      this.CountDown = Number(this.seconds)
      this.status = 1
      this.$emit('start')
      this.timer = setInterval(() => {
        const CountDown = this.CountDown - 1
        this.CountDown = CountDown
        this.$emit('change', this.changeText)
        if (CountDown <= 0) {
          this.status = 2
          this.$emit('end')
        }
      }, 1000)
    }

    reset (): void {
      this.status = 2
      this.CountDown = 0
      this.clearTimer()
    }

    beforeDestroy (): void {
      this.clearTimer()
    }
}
